<template>
  <div>
    <div>
      <div class="content1">
        <div class="all">
          <div class="SearchBar">
            <!--搜索框-->
            <el-form
                ref="search"
                :inline="true"
                :model="search"
                size="small"
                label-width="80px"
            >
              <el-form-item  label="时间段">
                <el-date-picker
                    v-model="times"
                    type="datetimerange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    clearable
                    value-format="yyyy-MM-dd HH:mm:ss"
                    :default-time="['00:00:00', '23:59:59']"
                    @change="getSearchDate"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="巡线员">
                <el-input
                    v-model="search.searchText"
                    placeholder="请输入巡线员姓名"
                    class="SearchInput"
                    clearable
                ></el-input>
              </el-form-item>
              <el-form-item label-width="20px" label=" ">
                <div>
                  <el-button type="primary" size="small" @click="getList(1)"
                  >查询</el-button
                  >
                  <el-button type="warning"  size="small" @click="reset"
                  >重置</el-button
                  >
                </div>
              </el-form-item>
            </el-form>
          </div>
          <div class="table">
            <!--数据表格-->
            <el-table
                ref="tableData"
                :data="tableData"
                style="width: 100%;"
                border
                height="calc(100vh - 333px)"
                :stripe="true"
                :header-cell-style="{
                  color: '#333',
                  fontFamily: 'MicrosoftYaHeiUI',
                  fontSize: '14px',
                  fontWeight: 900,
                  textAlign:'center',
                  background: '#f8f8f9',
                }"
            >
              <el-table-column
                  type="index"
                  label="序号"
                  width="70"
                  align="center"
              >
              </el-table-column>
              <el-table-column
                  prop="realname"
                  label="巡线员"
                  align="center"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="createTime"
                  label="提交时间"
                  align="center"
                  show-overflow-tooltip
              >
              </el-table-column>
              <el-table-column
                  prop="projectNumber"
                  label="提交内容"
                  align="center"
              >
                <template slot-scope="{row}">
                  <el-button type="primary" size="mini"
                  v-if="$anthButtons.getButtonAuth('lookContent')"
                   @click="showDetil(row)">查看</el-button>
                </template>
              </el-table-column>
            </el-table>
            <div class="pageBox">
              <el-pagination
                  :current-page="search.current"
                  :background="true"
                  :page-sizes="[10,30, 50, 100]"
                  :page-size="search.size"
                  layout="total, prev, pager, next, sizes, jumper"
                  :total="total"
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
              >
              </el-pagination>
            </div>
          </div>
          <!-- 查看图片 -->
          <el-dialog
            :visible.sync="showModel"
            :show-close="false"
            width='636px'
            top='35vh'
            :close-on-click-modal="false">
           <div v-for="(item,index) in detiltable.workImgArr" :key="index" style="margin-left: 10px;display: inline-block;">
                    <el-image 
                      style="width: 100px; height: 100px; margin-top: 18px;border: 1px solid #ECEDF1;"
                      :src="item" 
                      :preview-src-list="detiltable.workImgArr">
                    </el-image>
            </div>
            <span slot="footer" class="dialog-footer">
              <el-button @click="showModel = false">取 消</el-button>
            </span>
        </el-dialog>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getWorkLogPage} from "@/RequestPort/checkingIn";
export default {
  name: "workLocus",
  data(){
    return{
      search:{current:1,size: 100,startDate:'',endDate:'',searchText:''},
      times:[],
      tableData:[],
      showModel:false,
      detiltable:{},
      total:0
    }
  },
   mounted() {
    this.getList(1)
  },
  methods:{
    getSearchDate(){
      if(this.times && this.times.length>0){
        this.search.startDate = this.times[0]
        this.search.endDate = this.times[1]
      }else{
        this.search.startDate = '';
        this.search.endDate = '';
      }
      // this.getList(1)
    },
    showDetil(row){
      this.showModel = true;
      this.detiltable = row;
      if(row.workImg){
        this.detiltable.workImgArr = row.workImg.split(',')
      }else{
        this.detiltable.workImgArr = []
      }
      
    },
    getList(page){
      if(page){
        this.search.current = 1
      }
      getWorkLogPage(this.search).then(res=>{
        this.tableData  = res.data.records
        this.total = res.data.total
      })
    },
    reset(){
      this.search = {current:1,size: 100,startDate:'',endDate:'',searchText:''}
      this.times = []
      this.getList(1)
    },
    handleSizeChange(val){
      this.search.size=  val
      this.getList(1)
    },
    handleCurrentChange(val){
      this.search.current=  val
      this.getList()
    }
  }
}
</script>

<style scoped lang='less'>
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  margin: 10px;
  padding-top: 1%;
  background-color: #ffffff;
}
.table {
  padding: 20px 20px 15px 20px !important;
  border-radius: 5px;
  margin: 10px;
  background-color: #ffffff;
  height: auto;
}
/deep/.el-dialog__body{
  padding: 0 20px;
}
.el-pagination{
        margin: 15px 0px 0px;
  }
</style>