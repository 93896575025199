
import axios from '@/common/js/request'

export function getTrackWorkPage(params){  
    const getTrackWorkPage = axios({
        url: '/zypatrol/track/getTrackWorkPage',
        method: 'get',
        params
    })
    return getTrackWorkPage
}
export function getWorkLogPage(params){  
    const getWorkLogPage = axios({
        url: '/zypatrol/track/getWorkLogPage',
        method: 'get',
        params
    })
    return getWorkLogPage
}
// 查看工作轨迹
export function getWorkTrackPath(params){  
    const getWorkTrackPath = axios({
        url: '/zypatrol/track/getWorkTrackPath',
        method: 'get',
        params
    })
    return getWorkTrackPath
}